<section id="download" #download class="features-area">
    <div class="container">
        <div class="section-internal pt-100 pb-75">
            <div class="section-title text-center section-title-mirage">    
                <ng-container *ngIf="details">
                    <h2>{{details?.heading}}</h2>
                    <div class="section-heading-tag">
                        <span class="single-heading-tag">{{details?.description}}
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="icon-boxes-wrapper">
                <div class="row">
                    <!-- Data -->
                    <div class="col-lg-4 col-md-6" *ngFor="let download of DownladData">
                            <div class="d-flex">
                                <div class="single-iconic-box iconic-box-v3 wow fadeInUp exclusive-iconic-box-1" data-wow-delay="0.4s">
                                    <div class="iconic-box-icon">
                                        <img style="width:250px;height:250px;" [src]="'https://backend.pinpointeye.com/Download/'+download.image" alt="title">
                                    </div>
                                    <div class="iconic-box-body">
                                        <h5 class="iconic-box-title">{{download.heading}}</h5>
                                        <p class="iconic-box-content">
                                            {{download.description}}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img src="assets/img/images/arrow.png" class="iconic-box-icon vs-arrow">
                                </div>
                            </div>
                     
                    </div>
                    <!-- Data -->
                </div> <!-- /.row -->
            </div> <!-- /.icon-boxes-wrapper -->
        </div> <!-- /.section-internal -->
    </div> <!-- /.container -->
</section>
