<section id="flexipay" #flexipay class="counter-up-area pt-128 pb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="counter-up-thumbnails content-right-spacer wow fadeInUp" data-wow-delay="0ms"
                    data-wow-duration="1500ms" *ngIf="details">
                    <img class="counterup-main-thumbnail-top animate-float-bob-x subscribe"
                        style="width:540px;height:1000px;"
                        src="{{'https://backend.pinpointeye.com/FlexiPay/'+ details.image}}"
                        alt="countdown thumbnails top">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="counterup-wrapper">
                    <div class="row">
                        <!-- Single Counter Up -->
                        <div class="col-md-12 col-sm-12">
                            <div class="sub-app-content"  *ngIf="details">
                                <h6></h6>
                                <!-- <h6 class="h6"> <span>{{details.heading}} </span></h6> -->
                                <h2 class="sub-title">{{details.bold_text}}
                                </h2>
                                <br>
                                <br>
                                <p style="color: #959595;"> <span style="color: #25b54c;"> </span> {{details.text}}</p>
                            </div>
                            <br>
                            <br>
                            <div class="sub-btn" *ngIf="details">
                                <a   (click)="storeBtn()" routerLink="/{{details.button_link}}" href="/{{details.button_link}}" class="filled-btn">
                                    {{details.button_text}}<i class="fas fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                        <!-- Single Counter Up -->
                    </div> <!-- /.row -->
                </div> <!-- /.counterup-wrapper -->
            </div> <!-- /.col-md-7 -->
        </div> <!-- /.row -->
    </div> <!-- /.container-->
    <div class="side-element">
        <img  src="assets/PinpointWebAssets/Subscription/Element.png"/>
    </div>
    <div class="container">
        <div class="pb-50 border-bottom-purple"></div>
    </div>
</section>
