<section id="banner" #banner class="hero-area hero-v3 mt-5 bg-contain banner" *ngIf="details"
    style="background-image: url('https://backend.pinpointeye.com/Banner1/{{details.banner}}' )">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-7">
                <div class="hero-content">
                    <div class="section-particle-effect d-none d-lg-block">
                        <img class="particle-1 animate-rotate-me" src="assets/img/particle/particle-8.png"
                            alt="particle One">
                        <img class="particle-3 animate-zoominout" src="assets/img/particle/particle-9.png"
                            alt="particle Three">
                    </div>
                    <div class="section-title section-title-white">
                        <h1 class="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="2500ms">{{details?.text}}</h1>
                        <!-- id="about" #about -->
                        <div  id="playstore" #playstore  class="section-button-wrapper section-dual-button wow fadeInUp" data-wow-delay="0.4s"
                            data-wow-duration="2500ms" >
                            <!-- <a routerLink="/" (click)="storeBtn(playBtn)" > -->
                                <!-- <a routerLink="/" > -->
                                    <a href="https://play.google.com/store/apps/details?id=pinpointeye.app" target="_blank">

                                <img src="assets/PinpointWebAssets/Home/Play store Button.png" />
                                <!-- <div class="d-flex" class="google-btn">
                                    <i class="fab fa-google-play"></i>
                                    <p>Get it on <br> Google Play</p> 
                                </div> -->
                            </a>
                            <!-- <a routerLink="/" (click)="appBtn(appleBtn)"> -->
                                <!-- <a routerLink="/" > -->
                            <!-- <a href="https://apps.apple.com/" target="_blank"> -->

                                <!-- <div class="d-flex" class="store-btn>
                                    <div style="">
                                        <i class="fab fa-apple" style="font-size: 40px;"></i> 
                                    </div>
                                   <p> Get it on <br> Apple Store </p>
                                </div> -->
                                <!-- <img src="assets/PinpointWebAssets/Home/App Store button.png" /> -->
                            <!-- </a> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="hero-right-image-wrapper text-center text-lg-right wow fadeInUp" data-wow-delay="0.6s"
                    data-wow-duration="2500ms">
                    <ng-container *ngIf="details">
                        <img style="width:419px;height:682px;"
                            src="{{'https://backend.pinpointeye.com/Banner/'+details?.image}}"
                            alt="hero right image">
                    </ng-container>
                </div> <!-- /.blob-right-image-wrapper -->
            </div>
        </div> <!-- /.row -->
    </div> <!-- .container -->
</section>

<ng-template #playBtn>
    <!-- modal content goes here -->
    <div>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">my world</h5>
        </div>
        <div class="modal-body">
          hello....
        </div>
    </div>

</ng-template>

<ng-template #appleBtn>
    <!-- modal content goes here -->
    <div>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">my world</h5>
        </div>
        <div class="modal-body">
          hello....
        </div>
    </div>

</ng-template>
