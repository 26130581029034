<section id="login" #login class="newsletter-search-area newsletter-search-v2 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
    <div class="container">
        <div class="newsletter-search-internal text-center bg-dark-green pt-75 pb-80">
            <div class="newsletter-search-section-images d-none d-xl-block wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms" [style.background-image]="'url(assets/PinpointWebAssets/Login/Background-min.png)'"></div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title section-title-white" *ngIf="details"> 
                        <h2 class="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">{{details.heading}}</h2>
                        <p class="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">{{details.description}}</p>
                    </div>
                    <div class="newsletter-search-form wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div>
                            <a class="filled-btn" (click)="loginModalOpen(loginId)">
                            <!-- <a class="filled-btn"  routerLink="/login" href="/login"> -->
                                Login with &nbsp; &nbsp;
                                <img src="../../../../../assets/img/logo.png" style="width: 23px;" />
                                <img src="../../../../../assets/PinpointWebAssets/logo2.png" class="logo2" style="width: 23px;" />
                                
                            </a>
                        </div>
                        <p>Or</p>
                        <div>
                            <a class="filled-btn" (click)="openModal(readMore)"> 
                                Login with other
                            </a>
                            <!-- <a class="filled-btn" routerLink="/login-guest"> 
                                Login with other
                            </a> -->
                        </div>
                    </div>
                </div>
            </div> <!-- /.row -->
        </div> <!-- /.newsletter-search-internal -->
    </div> <!-- /.container -->
    <div class="section-bg-overflow bg-dark-black-v2"></div>
</section>

<ng-template #loginId>
    <div class="modal-body">
        <div class="container container2">
            <div class="align-items-center">
                <div class="contact-form-area">
                    <div class="section-title section-title2 mb-40">
                        <!-- <div class="section-heading-tag">
                            <span class="single-heading-tag">Send Us Message</span>
                        </div> -->
                        <h2 style="color: #fff;" >Login with Pin Point Eye </h2>
                    </div>
                        <div class="contact-respond">
                            <form [formGroup]="loginForm">
                                <!-- <div class="input-group">
                                    <input type="text" class="form-control" id="name" name="name" placeholder="Name"
                                        formControlName="name">
                                </div>
                                <div *ngIf="loginForm.get('name').invalid && (loginForm.get('name').dirty || loginForm.get('name').touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="loginForm.get('name').errors.required">
                                        Name is required.
                                    </div>
                                </div> -->
                                <label for="" style="color: #fff;" >Username</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="username" name="username" placeholder="username"
                                        formControlName="username">
                                </div>
                                <div *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="loginForm.get('username').errors.required">
                                        Username is required.
                                    </div>
                                </div>
                                <label for="" style="color: #fff;"  >Password</label>
                                <div class="input-group">
                                    <input type="password" class="form-control" id="password" name="password"
                                        placeholder="Password" formControlName="password">
                                </div>
                                <div *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="loginForm.get('password').errors.required">
                                        Password is required.
                                    </div>
                                    <!-- <div *ngIf="loginForm.get('password').errors.pattern">
                                        Password must contain at least 12 characters, one uppercase letter, one lowercase letter, and one number.
                                    </div> -->
                                </div>
                                <div id="hideDiv" class="bg-success" *ngIf="toastr">
                                    {{toastr}}  
                                </div>
                                <div id="hideDiv" class="bg-danger" *ngIf="errorMsg">
                                    {{errorMsg}}  
                                </div>
                                <div class="input-group">
                                    <button type="submit" class="filled-btn" style=" color: #24B64D;"  (click)="onLoginSubmit()">Login <i
                                            class="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #readMore>
<!-- <div class="modal-header">
    <h4 class="modal-title">Fullscreen Modal</h4>
    <button type="button" class="close" aria-label="Close" >
      <span aria-hidden="true">&times;</span>
    </button>

  </div> -->
  <div class="modal-body">  
    <div class="container container2">
        <div class="align-items-center">
            <div class="contact-form-area">
                <div class="section-title section-title2 mb-40">
                    <h2 style="color: #fff;" >Login with Guest</h2>
                </div>
                <div class="contact-respond">
                    <form [formGroup]="loginGuestForm">
                        <label for="" style="color: #fff;" >Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Name"
                                formControlName="name">
                        </div>
                        <div *ngIf="loginGuestForm.get('name').invalid && (loginGuestForm.get('name').dirty || loginGuestForm.get('name').touched)"
                            class="alert alert-danger">
                            <div *ngIf="loginGuestForm.get('name').errors.required">
                                Name is required.
                            </div>
                        </div>
                        <label for="" style="color: #fff;" >Email Address </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="email" name="email" placeholder="Email Address"
                                formControlName="email">
                        </div>
                        <div *ngIf="loginGuestForm.get('email').invalid && (loginGuestForm.get('email').dirty || loginGuestForm.get('email').touched)"
                            class="alert alert-danger">
                            <div *ngIf="loginGuestForm.get('email').errors.required">
                                Email address is required.
                            </div>
                        </div>
                        <div id="hideDiv" class="bg-success" *ngIf="toastr">
                            {{toastr}}  
                        </div>
                        <div id="hideDiv" class="bg-danger" *ngIf="errorMsg">
                            {{errorMsg}}  
                        </div>
                        <div class="input-group">
                            <button type="submit" class="filled-btn" style=" color: #24B64D;" (click)="onSubmit()">Login
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>