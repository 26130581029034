import { Injectable } from '@angular/core';
import testimonial from "../../data/testimonials.json";
import author from "../../data/team.json";
import clients from "../../data/clients.json";
import { PinnPontService } from '../pinn-pont.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TestimonialHelperService {
  // pagination
  maxLength: number = 150;
  threeDots: string = '...more';
  public items: any;
  public showFullContent = false;
  public testimonials = testimonial;
  public details: any;
  public desc: any;
  public FeatureHeading: any; 
  constructor(private feature: PinnPontService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.feature.getfeature().subscribe((res: any)=> {
      this.details = res.Feature;
      this.FeatureHeading = res.FeatureHeading;
    }, (error) => {
        console.log('--->', error);   
    })
  }

  openModal(readMore: any, content: any, i:number) {
    this.items = content[i];
    this.modalService.open(readMore);
  }
  // Author
  public getAuthor(items: string | any[]) {
    var elems = author.filter((item: { id: string; }) => {
      return items.includes(item.id)
    });
    return elems;
  }
  // Clients
  public getClients(items: string | any[]) {
    var elems = clients.filter((item: { id: string; }) => {
      return items.includes(item.id)
    });
    return elems;
  }
}
