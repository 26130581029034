import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';
import { GlobalConstants } from '../shared/global-contants';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  errorMsg: any;
  constructor(
    public auth: AuthService,
    public router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const token = localStorage.getItem('token');

    if (!token) {
      // this.router.navigate(['/direct-page']);
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
    
    // let execptedRoleArray = route.data;
    // execptedRoleArray = execptedRoleArray.expectedRole;

    // const token: any = localStorage.getItem('token');
    // let tokenPayload: any;
    // try {
    //   tokenPayload = jwt_decode(token);
      
    // } catch (error) {
    //   localStorage.clear();
    //   this.router.navigate(['/login']);
    // }

    // let checkRole = false;

    // for (let i = 0; i < execptedRoleArray.length; i++) {
    //   if (execptedRoleArray[i] == tokenPayload.data.role) {
    //     checkRole = true;
    //   }
    // }

    // if (tokenPayload.data.role == 'user' || tokenPayload.data.role == 'admin') {
    //   if (this.auth.isAuthencated() && checkRole) {
    //     return true;
    //   }
      
    //   this.errorMsg = [GlobalConstants.unauthroized,
    //     GlobalConstants.error];

    //   this.router.navigate(['/login']);
    //   return false;
    // } else {
    //   localStorage.clear();
    //   this.router.navigate(['/login']);
    //   return false;
    // }
  }
}
