import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularDeviceInformationService, DeviceInfo } from 'angular-device-information';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import { GlobalConstants } from 'src/app/components/shared/global-contants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  bsModalRef: any;
  deviceInfo:DeviceInfo | any;
  public details: any;
  public toastr: any;
  public errorMsg: any;
  responseMessage: string | any;

  constructor(private formBulider: FormBuilder,
    private userService: PinnPontService,
    private modalService: NgbModal,
    private router: Router,
    private deviceInformationService:AngularDeviceInformationService) { }

  loginForm: FormGroup | any = FormGroup;
  loginGuestForm: FormGroup | any = FormGroup;

  ngOnInit(): void {
    this.deviceInfo = this.deviceInformationService.getDeviceInfo();
    this.userService.getlogin().subscribe((res: any) => {
      this.details = res.Login
    }, (error) => {
      console.log('--->', error);
    })

    this.loginForm = this.formBulider.group({
      username: [
        '',
        [Validators.required],
      ],
      password: ['', [Validators.required]],
    });


    this.loginGuestForm = this.formBulider.group({
      email: [
        '',
        [Validators.required],
      ],
      name: ['', [Validators.required]],
    });
  }

  openModal(readMore: any) {
    // this.bsModalRef = this.modalService.open(readMore, {  scrollable: true, size: 'xl', backdrop: 'static' , centered: true });
    this.bsModalRef = this.modalService.open(readMore, { windowClass: 'myCustomModalClass' ,  scrollable: false, centered: true });
    this.bsModalRef.result.then(
      () => {
        // Modal closed successfully
      },
      () => {
        // Modal dismissed
        this.resetData();
      }
    );
  }

  resetData() {
    // Reset the data here
    this.loginForm.reset();
    this.loginGuestForm.reset();

  }

  loginModalOpen(id: any) {
    this.bsModalRef = this.modalService.open(id, { windowClass: 'myCustomModalClass' , scrollable: false,  centered: true });
    this.bsModalRef.result.then(
      () => {
        // Modal closed successfully
      },
      () => {
        // Modal dismissed
        this.resetData();
      }
    );
  }

  onSubmit() {
    var formData = this.loginGuestForm.value;
    var data = {
      email: formData.email,
      name: formData.name,
    };

    console.log('data--->', data);
    

    this.userService.postUserLogin(data).subscribe(
      (response: any) => {
        console.log('----->', response);
        localStorage.setItem('token', response.token);
        this.toastr = response?.message;
        this.router.navigate(['/']);
        window.location.reload();
      },
      (error) => {
        if (error.error) {
          console.log('message--->', error.error);

          this.errorMsg = error.error?.message || error.error.errors;
          this.responseMessage = error.error?.message;
        } else {
          this.responseMessage = GlobalConstants.genericError;
        }
        // this.router.navigate(['/login']);
      }
    );
  }

  onLoginSubmit() {
    var data = this.loginForm.value;
    // var data = {
    //   name: formData.name,
    //   email: formData.email,
    //   password: formData.password,
    // };
   
    
    const formData = new FormData();
    formData.append('LoginForm[device_type]', '1');
    formData.append('LoginForm[username]', 'mohammad.omer.raza@gmail.com');
    formData.append('LoginForm[password]', '12345678');
    formData.append('LoginForm[device_token]', 'eMKpKQRaSPmJ8Tky4CQICu:APA91bERpW5KdK3I8XnyVW5q8haph4epRh8XWnh8B6c3Inp3yy5Vgl3QQXTTNDCE7aRoxZD6VlhdPFGVTXFNhvripZeBRBGiaJhuIMRx_rQZ7xi4f183uM0uUSdFXvADsek7phZvvDS');
    formData.append('LoginForm[device_name]', 'Google sdk_gphone_x86');

    // formData.append('LoginForm[username]', data.username);
    // formData.append('LoginForm[password]', data.password);
    // // formData.append('LoginForm[device_type]', this.deviceInfo?.deviceType);
    // formData.append('LoginForm[device_type]', '1');
    // formData.append('LoginForm[device_token]', 'eMKpKQRaSPmJ8Tky4CQICu:APA91bERpW5KdK3I8XnyVW5q8haph4epRh8XWnh8B6c3Inp3yy5Vgl3QQXTTNDCE7aRoxZD6VlhdPFGVTXFNhvripZeBRBGiaJhuIMRx_rQZ7xi4f183uM0uUSdFXvADsek7phZvvDS');
    // // formData.append('LoginForm[device_name]', this.deviceInfo.os);    
    // formData.append('LoginForm[device_name]', 'Google sdk_gphone_x86');    
    this.userService.omerUserLogin(formData).subscribe(
      (response: any) => {
        console.log('----->', response);
        localStorage.setItem('token', response.token);
        this.toastr = response?.message;
        // this.toastService.show(response.message, { classname: 'bg-success text-light', delay: 5000});
        // this.router.navigate(['/']);
      },
      (error) => {
        if (error.error) {
          console.log('error-->', error.error);
          // this.snackBarService.openSnackBar( error.error?.message, '')
          this.errorMsg = error.error.errors;
          this.responseMessage = error.error?.message;
        } else {
          this.responseMessage = GlobalConstants.genericError;
          // this.snackbarService.openSnackBar(
          //   this.responseMessage,
          //   GlobalConstants.error
          // );
        }
        // this.router.navigate(['/login']);
      }
    );
  }
}
