<!-- <a (click)="scroll(about)">Go to section 1</a> -->
 <section id="about" #about class="manage-data-area pt-120">
    <div class="container">
        <div class="row align-items-center border-bottom-purple" [ngClass]="{'pb-50': pbClass}">
            <div class="col-lg-7">
                <div *ngIf="details" class="manage-data-image content-right-spacer animate-float-bob-y wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <img src="{{'https://pinpointeye.com/About/'+details?.About?.image}}"  style="width:445px;height:900px;" alt="Manage Data">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="section-title section-title-tuna">
                    <h2>{{heading?.heading}}</h2>
                    <div class="section-title-description">
                        <p>{{details?.About?.description}}</p>
                    </div>
                </div>
                <div class="iconic-list-wrapper">
                    <!-- <div class="single-iconic-list iconic-list-bg iconic-list-padding wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="iconic-list-icon">
                            <i class="fas fa-check"></i>
                        </div>
                        <div class="iconic-list-body">
                            <h5 class="iconic-list-title">{{firstElement?.heading}}</h5>
                            <p class="iconic-list-content">
                                {{firstElement?.description}}
                            </p>
                        </div>
                    </div> -->
                    <div  *ngFor="let data of abtFeature; let i = index" >
                        <div class="single-iconic-list iconic-list-padding wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div class="iconic-list-icon">
                                <i class="fas fa-check"></i>
                            </div>
                            <div class="iconic-list-body">
                                <h5 class="iconic-list-title">{{data?.heading}}</h5>
                                <p class="iconic-list-content">
                                    {{data?.description}} 
                                </p>
                            </div>

                        </div>
                    </div>
                    <!-- id="feature" #feature  -->
                    <div class="single-iconic-list iconic-list-padding wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="iconic-list-icon">
                            <i class="fas fa-check"></i>
                        </div>
                        <div class="iconic-list-body">
                            <h5 class="iconic-list-title">{{lastAbtFeature?.heading}}</h5>
                            <p class="iconic-list-content">
                                {{lastAbtFeature?.description}} 
                            </p>
                        </div>
                    </div>
<!--                   
                    <div class="single-iconic-list iconic-list-padding wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="iconic-list-icon">
                            <i class="fas fa-check"></i>
                        </div>
                        <div class="iconic-list-body">
                            <h5 class="iconic-list-title">Devices With Quality</h5>
                            <p class="iconic-list-content">
                                But I must explain to you how all mistaken denouncing pleasure and praising
                            </p>
                        </div>
                    </div>

                    <div class="single-iconic-list iconic-list-padding wow fadeInDown" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="iconic-list-icon">
                            <i class="fas fa-check"></i>
                        </div>
                        <div class="iconic-list-body">
                            <h5 class="iconic-list-title">Devices With Quality</h5>
                            <p class="iconic-list-content">
                                But I must explain to you how all mistaken denouncing pleasure and praising
                            </p>
                        </div>
                    </div> -->
                </div>
                <div ></div>
            </div>
        </div> <!-- /.row -->
    </div> <!-- /.container -->
</section>
