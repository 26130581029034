import { Component, OnInit } from '@angular/core';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import data from "../../../data/workprocess.json";

@Component({
  selector: 'app-page-cta',
  templateUrl: './page-cta.component.html',
  styleUrls: ['./page-cta.component.css']
})
export class PageCtaComponent implements OnInit {
  /** download */
  public workprocess = data;
  public details:any;
  public DownladData:any;
  constructor(private download: PinnPontService) { }

  ngOnInit(): void {
    this.download.getdownload().subscribe((res: any)=> {
      this.details = res.Downladdh
      this.DownladData = res.Downlad
    }, (error) => {
        console.log('--->', error);
        
    })
  }
}
