import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PinnPontService {
  public url = environment.apiUrl
  public userurl = environment.userApi
  constructor(private http: HttpClient ) { }

  public getbanner() { 
    return this.http.get(this.url + '/banner'); 
  } 
  public getabout() { 
    return this.http.get(this.url + '/about'); 
  } 
  public getfeature() { 
    return this.http.get(this.url + '/feature'); 
  } 
  public gethtu() { 
    return this.http.get(this.url + '/htu'); 
  } 
  public getfaq() { 
    return this.http.get(this.url + '/faq'); 
  } 
  public getdownload() { 
    return this.http.get(this.url + '/download'); 
  } 

  public getflexipay() { 
    return this.http.get(this.url + '/flexipy'); 
  } 

  public getlogin() { 
    return this.http.get(this.url + '/login'); 
  } 

  public getfooter() { 
    return this.http.get(this.url + '/footer'); 
  } 

  public omerUserLogin(data?:any) {     
    return this.http.post(this.userurl + '/user/login', data, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      }),
    }); 
  } 

  public postUserLogin(data?:any) { 
    return this.http.post(this.url + '/user/login', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }); 
  } 

  public postUserRegister(data?:any) { 
    return this.http.post(this.url + '/user/register', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    }); 
  } 

  public postUserLoginGuest(data?:any) { 
    return this.http.post(this.url + '/guest/register', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }); 
  } 
}
