import { Component, OnInit } from '@angular/core';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import data from "../../../data/companyfeat.json";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  /** how to use */ 
  public features = data;
  public details: any;
  public HTUImage: any;
  constructor(private htu: PinnPontService) { }

  ngOnInit(): void {
    this.htu.gethtu().subscribe((res: any)=> {
      this.details =  res.HTUFeature1
      this.HTUImage =  res.HTUImage1
    }, (error) => {
        console.log('--->', error);    
    })
  }

}
