<app-preloader></app-preloader>
<!-- <div [@triggerName]="prepareRoute(outlet)">
    <router-outlet (activate)="onActivate($event)" #outlet="outlet"></router-outlet>
</div> -->
<!-- <app-header></app-header>
<app-banner></app-banner>
<app-about id="about"  #about></app-about>
<app-testimonials id="feature" #feature></app-testimonials>
<app-features  id="howtouse" #howtouse></app-features>
<app-stats  id="faqs" #faqs></app-stats>
<app-counter  id="flexipay" #flexipay></app-counter>
<app-page-cta  id="download" #download></app-page-cta>
<app-login id="login" #login></app-login>
<app-footer></app-footer>  -->
<router-outlet (activate)="onActivate($event)"></router-outlet>