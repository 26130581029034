import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularDeviceInformationService, DeviceInfo } from 'angular-device-information';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  deviceInfo: DeviceInfo | any;
  public details: any;

  constructor(private banner: PinnPontService,
    private modalService: NgbModal,
    private deviceInformationService: AngularDeviceInformationService) { }

  ngOnInit(): void {
    this.deviceInfo = this.deviceInformationService.getDeviceInfo();
    this.banner.getbanner().subscribe((res: any) => {
      this.details = res?.Banner;
    }, (error) => {
      console.log('--->', error);

    })
  }

  // using link
  storeBtn(btn: any) {
    console.log('this.deviceInfo',this.deviceInfo?.os, !this.deviceInfo?.os.includes("OS"));
    if (!(this.deviceInfo?.os.includes("OS"))) {
      window.location.href = 'https://mail.google.com/mail/u/0/#inbox?compose=new';
    } else {
      this.modalService.open(btn);
    }
  }

  appBtn(appleBtn: any) {
    console.log('this.deviceInfo',this.deviceInfo?.os, this.deviceInfo?.os.includes("OS"));
    if (this.deviceInfo?.os.includes("OS")) {
      window.location.href = 'https://mail.google.com/mail/u/0/#inbox?compose=new';
    } else {
      this.modalService.open(appleBtn);
    }
  }
}
