<section id="login" #login class="newsletter-search-area newsletter-search-v2 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
    <div class="container">
        <div class="newsletter-search-internal text-center bg-dark-green pt-75 pb-80">
            <div class="newsletter-search-section-images d-none d-xl-block wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1500ms" [style.background-image]="'url(assets/PinpointWebAssets/Login/Background-min.png)'"></div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title section-title-white" *ngIf="details">
                        <h2 class="wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                            {{ details.heading }}
                        </h2>
                        <div class="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms" 
                             style="background-color: #ffeb3b; padding: 15px; border-radius: 5px; margin-top: 15px; text-align: center;">
                            <a (click)="videoModalOpen(videoModal)"
                               style="color: #6e727d; text-decoration: underline; font-weight: 600; cursor: pointer;">
                                Click here to view the Login &amp; Share video short
                            </a>
                        </div>
                        <!-- <p class="wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                            {{ details.description }}
                        </p>
                        -->
                    </div>
                    <div class="newsletter-search-form wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div>
                            <a class="filled-btn" (click)="loginModalOpen(loginId)">
                            <!-- <a class="filled-btn"  routerLink="/login" href="/login"> -->
                                Login with &nbsp; &nbsp;
                                <img src="../../../../../assets/img/logo.png" style="width: 23px;" />
                                <img src="../../../../../assets/PinpointWebAssets/logo2.png" class="logo2" style="width: 23px;" />
                                
                            </a>
                        </div>
                        <p>Or</p>
                        <div>
                            <a class="filled-btn" (click)="openModal(readMore)"> 
                                Login with other
                            </a>
                            <!-- <a class="filled-btn" routerLink="/login-guest"> 
                                Login with other
                            </a> -->
                        </div>
                    </div>
                </div>
            </div> <!-- /.row -->
        </div> <!-- /.newsletter-search-internal -->
    </div> <!-- /.container -->
    <div class="section-bg-overflow bg-dark-black-v2"></div>
</section>


<ng-template #loginId>
    <div class="modal-body" style="width: 100%">
        <div class="container container2">
            <div class="align-items-center">
                <div class="contact-form-area">
                    <div class="section-title section-title2 mb-40">
                        <h2 style="color: #fff;">Login with Pinpointeye</h2>
                    </div>
                    <div class="contact-respond">
                        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                            <label style="color: #fff;">Username</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Username" formControlName="username">
                            </div>
                            <div *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)" style="margin-top:5px;">
                                <span style="color:#721c24; background-color:#f8d7da; padding:8px; border-radius:5px; display:block;">
                                    Username is required.
                                </span>
                            </div>

                            <label style="color: #fff; margin-top:15px;">Password</label>
                            <div class="input-group">
                                <input type="password" class="form-control" placeholder="Password" formControlName="password">
                            </div>
                            <div *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)" style="margin-top:5px;">
                                <span style="color:#721c24; background-color:#f8d7da; padding:8px; border-radius:5px; display:block;">
                                    Password is required.
                                </span>
                            </div>

                            <div *ngIf="toastr" style="margin-top:10px;">
                                <span style="color:#fff; background-color:#28a745; padding:8px; border-radius:5px; display:block;">
                                    {{toastr}}
                                </span>
                            </div>

                            <div *ngIf="errorMsg" style="margin-top:10px;">
                                <span style="color:#721c24; background-color:#f8d7da; padding:8px; border-radius:5px; display:block;">
                                    {{errorMsg}}
                                </span>
                            </div>

                            <div class="input-group" style="margin-top:15px;">
                                <button type="submit" class="filled-btn" style="color: #24B64D;">
                                    Login <i class="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #readMore>
<!-- <div class="modal-header">
    <h4 class="modal-title">Fullscreen Modal</h4>
    <button type="button" class="close" aria-label="Close" >
      <span aria-hidden="true">&times;</span>
    </button>

  </div> -->
  <div class="modal-body" style="width: 100%;">  
    <div class="container container2">
        <div class="align-items-center">
            <div class="contact-form-area">
                <div class="section-title section-title2 mb-40">
                    <h2 style="color: #fff;" >Login with Guest</h2>
                </div>
                <div class="contact-respond">
                    <form [formGroup]="loginGuestForm" (ngSubmit)="onSubmit()">
                        <label style="color: #fff;">Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name">
                        </div>
                        <div *ngIf="loginGuestForm.get('name').invalid && (loginGuestForm.get('name').dirty || loginGuestForm.get('name').touched)" class="alert alert-danger">
                            <div *ngIf="loginGuestForm.get('name').errors.required">Name is required.</div>
                        </div>
                    
                        <label style="color: #fff;">Email Address</label>
                        <div class="input-group">
                            <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                        </div>
                        <div *ngIf="loginGuestForm.get('email').invalid && (loginGuestForm.get('email').dirty || loginGuestForm.get('email').touched)" class="alert alert-danger">
                            <div *ngIf="loginGuestForm.get('email').errors.required">Email address is required.</div>
                            <div *ngIf="loginGuestForm.get('email').errors.email">Please enter a valid email address.</div>
                        </div>
                    
                        <div class="bg-success" *ngIf="toastr">{{toastr}}</div>
                        <div class="bg-danger" *ngIf="errorMsg">{{errorMsg}}</div>
                    
                        <div class="input-group">
                            <button type="submit" class="filled-btn" style="color: #24B64D;">Login<i class="fas fa-arrow-right"></i></button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>
<!-- New popup that shows a YouTube video -->
<ng-template #videoModal>
    <div class="modal-body" style="width: 100%;">
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/cOPqott5iCs"
        frameborder="0"
        allowfullscreen
        style="display: block;"
      ></iframe>
    </div>
  </ng-template>