<header class="header-area header-v1">
    <!-- <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on' , windowScrolled ? 'sticky':'']"
        (window:resize)="detectHeader()"> -->
        <div class="header-navigation sticky" [ngClass]="[visible ? '' : 'breakpoint-on']"
        (window:resize)="detectHeader()">
        <div class="container-fluid">
            <div class="row align-items-center">
                <!-- Brand Logo and Language Selection Dropdown -->
                <div class="col-xl-2 col-lg-4 col-md-5 col-8">
                    <div class="site-branding-and-language-selection">
                        <div class="brand-logo">
                            <a routerLink="/">
                                
                                <!-- <img src="assets/PinpointWebAssets/logo.png" alt="logo"> -->
                                <img src="assets/img/logo.png" alt="logo">
                                <h4 class="logoName"> <b>PinPointEye</b> </h4>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Desktop and Mobile Menu -->
                <div class="col-xl-8 col-lg-3 col-md-1 col-2 text-center">
                    <div class="primary-menu">
                        <div class="nav-menu" [ngClass]="navMethod ? 'menu-on':''">
                            <!-- Navbar Close Icon -->
                            <div class="navbar-close" (click)="toggleNav()">
                                <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                            </div>
                            <nav class="main-menu">
                                <ul>
                                    <!-- Data -->
                                    <li class="menu-item">
                                        <a routerLinkActive="active" (click)="bannerPage()">Home</a> 
                                    </li>
                                    <li class="menu-item">
                                        <a  (click)="aboutPage()">About</a> 
                                    </li>
                                    <li class="menu-item">
                                        <a  (click)="featurePage()">Features</a> 
                                    </li>
                                    <li class="menu-item">
                                        <a  (click)="howtousePage()">How to Use</a> 
                                    </li>
                                    <li class="menu-item">
                                        <a  (click)="faqsPage()">FAQ's</a> 
                                    </li>
                                    <li class="menu-item">
                                        <a  (click)="flexipayPage()">Flexi-pay</a> 
                                    </li>
                                    <li class="menu-item" *ngIf="btn">
                                        <a  (click)="downloadPage()">Downloads</a> 
                                    </li>
                                    <li class="loginBtn" *ngIf="!btn" >
                                        <a (click)="loginPage()">
                                            login
                                        </a>
                                    </li>
                                    <li class="loginBtn" *ngIf="btn" >
                                        <a (click)="logout()">
                                            logout
                                        </a>
                                    </li>
                                   </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!-- Menu Right -->
                <div class="col-xl-2 col-lg-5 col-md-6 col-2">
                    <div class="header-right">
                        <ul>
                            <li class="get-started-wrapper" *ngIf="!btn" >
                                <a class="filled-btn" (click)="loginPage()">
                                    Login <i class="fas fa-arrow-right"></i>
                                </a>
                            </li>
                            <li class="get-started-wrapper" *ngIf="btn" >
                                <a class="filled-btn" (click)="logout()">
                                    Logout <i class="fas fa-arrow-right"></i>
                                </a>
                            </li>
                            <li>
                                <div class="menu-toggle" [ngClass]="navMethod ? 'active':''">
                                    <div class="menu-overlay" (click)="toggleNav()"></div>
                                    <!-- Navbar Toggler -->
                                    <div class="nav-toggle">
                                        <div class="navbar-toggler float-end" [ngClass]="navMethod ? 'active':''"
                                            (click)="toggleNav()">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> <!-- /.row -->
        </div> <!-- /.container-fluid -->
    </div> <!-- /.header-navigation -->
</header>
<ng-template #searchmodal let-modal>
    <app-search-box></app-search-box>
</ng-template>