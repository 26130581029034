import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from './components/helper/route-guard.service';

const routes: Routes = [
  //canActivate: [RouteGuardService] ,
  // Home
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Homepage", animation: 'Home' } }, 
 { path: 'login', loadChildren: () => import('./components/pages/login-feature/login-feature.module').then(m => m.LoginFeatureModule), data: { breadcrumb: "Login" } },
//  //login-guest
 { path: 'login-guest', loadChildren: () => import('./components/pages/login-guest/login-guest.module').then(m => m.LoginGuestModule), data: { breadcrumb: "Login Guest" } },
  { path: 'error', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: 'Error 404' } },
  { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'Privacy Policy' } },

{ path: '**', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: 'Error 404' } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
