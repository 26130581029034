import { Injectable, HostListener, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import data from "../data/navigation.json";
import footerlinks from "../data/footerlinks.json";
import $ from 'jquery';
import 'magnific-popup';
import { PinnPontService } from './pinn-pont.service';
import { Location, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelperService implements AfterViewInit, OnInit {
  public btn:any;
  public navigation = data;
  public links = footerlinks;
  windowScrolled: boolean | undefined;
  closeResult: string | undefined;
  constructor(private router: Router,
    private scroller: ViewportScroller,
    private location: Location,
     private modalService: NgbModal,
     private route: ActivatedRoute, 
     private htu: PinnPontService) { }

  public FooterCol1: any;
  public FooterCol2: any;
 
  // Search
  openSearch(content: any) {
    this.modalService.open(content, { centered: true, windowClass: 'search-modal' });
  }
  // Sticky Nav
  @HostListener("window:scroll", [])
  
  onWindowScroll() {
    this.windowScrolled = window.scrollY > 100;
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  scroll(el?: HTMLElement|any) {
    // el.scrollIntoView({ behavior: 'smooth' });
  }

  bannerPage(){
    this.toggleNav()
    console.log(this.router.url);
    if (this.router.url == '/') {
      document.getElementById("banner")?.scrollIntoView({behavior: 'smooth'});  
    }else{
      this.router.navigateByUrl('/')
    }
  }

  aboutPage(){  
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("about")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  featurePage(){
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("feature")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  howtousePage(){
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("howtouse")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  faqsPage(){
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("faqs")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  flexipayPage(){
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("flexipay")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  downloadPage(){
    this.toggleNav()
    if(localStorage.getItem('token') != null)
    {
      this.router.navigate(['/downloads']);
    }
    else if (this.router.url == '/' && localStorage.getItem('token') == null) {  
      document.getElementById("download")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  loginPage(){
    this.toggleNav()
    if (this.router.url == '/') {  
      document.getElementById("login")?.scrollIntoView({behavior: 'smooth'});
    }else{
      this.router.navigateByUrl('/')
    }
  }
  reloadPage(page:any) {

    console.log('--->.', page); 
    document.getElementById(page)?.scrollIntoView({behavior: 'smooth'});
    // this.scroller.scrollToAnchor(page);  
    // this.location.go(this.location.path());
    // window.location.reload();

    // const id = this.route.snapshot.queryParamMap.get('id');

    // Use Renderer2 to scroll to the target element
    // if (id === page) {
    //   const targetElement = document.getElementById(page);
    //   if (targetElement) {
    //     targetElement.scrollIntoView({ behavior: 'smooth' });
    //   }
    // }
  }

  // navigation
  navMethod: boolean = false;
  toggleNav() {    
    this.navMethod = !this.navMethod;
  }
  // Canvas
  canvasMethod: boolean = false;
  toggleCanvas() {
    this.canvasMethod = !this.canvasMethod;
  }
  //Mobile 
  open: boolean = false;
  trigger(item: { open: boolean; }) {
    item.open = !item.open;
  }
  // Add class on resize and onload window
  visible: boolean = false;
  breakpoint: number = 1199;
  public innerWidth: any;
  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.visible = this.innerWidth >= this.breakpoint;
  }
  ngOnInit(): void {
    this.btn = localStorage.getItem('token');
    this.detectHeader();

    this.htu.getfooter().subscribe((res: any)=> {
      this.FooterCol1 =  res.FooterCol1
      this.FooterCol2 =  res.FooterCol2
    }, (error) => {
        console.log('--->', error);    
    })
  }

  logout() {
    localStorage.clear();
    // this.router.navigateByUrl('/')
    this.router.navigate(['/']);
    // window.location.reload();
  }

  ngAfterViewInit(): void {
    ($('.popup-youtube') as any).magnificPopup({
      type: 'iframe'
    });
    ($('.gallery-img-popup') as any).magnificPopup({
      type: 'image',
      gallery: {
        enabled: true,
      },
      mainClass: 'mfp-fade',
    });
  }
}
