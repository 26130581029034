<app-header></app-header>
<app-banner></app-banner>
<app-about></app-about>
<app-testimonials ></app-testimonials>
<app-features></app-features>
<!-- <app-services></app-services> -->
<app-stats></app-stats>
<app-counter></app-counter>
<app-page-cta></app-page-cta>
<app-login></app-login>
<app-footer></app-footer> 