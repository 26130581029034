<footer class="footer-area footer-area-v1" style="background-color: #252525;"
    [style.background-image]="'url(assets/img/footer-bg.png)'">
    <div class="container">
        <div class="footer-area-internal">
            <div class="row">
                <!-- Single Footer Widget -->
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="single-footer-widget footer-text-widget" *ngIf="FooterCol1">
                        <img src="assets/img/logo.png" alt="footer logo">
                        <p>{{FooterCol1.description}}
                        </p>

                        <div class="footer-social-links">
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=61556946285789" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/pinpointeye00?e=2d1ee38b-9004-4664-b7ba-cda0ae2561e5&g=5" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@PinPointEye00" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/pinpointeye-com/?viewAsMember=true" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Single Footer Widget -->
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s" *ngFor="let data of links">
                    <div class="single-footer-widget">
                        <h5 class="widget-title">{{data.title}}</h5>
                        <div class="footer-widget-menu">
                            <ul>

                                <!-- <li>
                                    <a  (click)="downloadPage()">Downloads</a> 
                                </li> -->
                                <!-- <li *ngFor="let item of data.items">
                                    <ng-container *ngIf="data.title == 'Quick Links'">
                                        <a>{{item.title}}</a>
                                    </ng-container>
                                    <ng-container *ngIf="data.title == 'Features'">
                                        <a>{{item.title}}</a>
                                    </ng-container>
                                </li> -->
                                <ng-container *ngIf="data.title ==  'Features'">

                                    <li>
                                        <a>People Screen</a>
                                    </li>
                                    <li>
                                        <a>Saved Places</a>
                                    </li>
                                    <li>
                                        <a>Home Address</a>
                                    </li>
                                    <li>
                                        <a>Phone Contacts</a>
                                    </li>
                                    <li>
                                        <a>User Guide</a>
                                    </li>
                                    <li>
                                        <a>Emergency Contacts</a>
                                    </li>
                                </ng-container>

                                <ng-container *ngIf="data.title ==  'Quick Links'">

                                    <li>
                                        <a (click)="bannerPage()">Home</a>
                                    </li>
                                    <li>
                                        <a (click)="aboutPage()">About</a>
                                    </li>
                                    <li>
                                        <a (click)="featurePage()">Features</a>
                                    </li>
                                    <li>
                                        <a (click)="howtousePage()">How to Use</a>
                                    </li>
                                    <li>
                                        <a (click)="faqsPage()">FAQ's</a>
                                    </li>
                                    <li>
                                        <a (click)="flexipayPage()">Flexi-pay</a>
                                    </li>
                                    <li>
                                        <a routerLink="/privacy-policy">Privacy Policy</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Single Footer Widget -->
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s" *ngIf="FooterCol2">
                    <div class="single-footer-widget">
                        <h5 class="widget-title">Find Us</h5>
                        <p>{{FooterCol2.description}}</p>
                        <p><a href="https://www.pinpointeye.com/#/privacy-policy" target="_blank">Privacy Policy</a></p>
                        <div class="footer-social-links">
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=61556946285789" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/pinpointeye00?e=2d1ee38b-9004-4664-b7ba-cda0ae2561e5&g=5" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@PinPointEye00" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/pinpointeye-com/?viewAsMember=true" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> <!-- /.row -->
        </div> <!-- /.footer-area-internal -->
    </div> <!-- /.container -->
    <hr style="border: 1px solid white" />
    <div class="footer-copyright">
        <p>&copy; 2024 PinPointEye All Rights Reserved</p>
    </div>
</footer>
<a href="javascript:void(0)" [ngClass]="windowScrolled ? 'd-inline-block':''" (click)="scrollToTop()"
    class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>