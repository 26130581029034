<section id="faqs" #faqs class="collaboration-area bg-cover pt-149" [style.background-image]="'url(assets/PinpointWebAssets/FAQ_s/Background.png)'">
    <div class="container">
        <div class="section-internal position-relative">
            <div class="row">
                <div class="col-lg-7">
                    <div class="collaboration-content pb-130">
                        <div class="section-title section-title-tuna">
                            <h2>{{FaqHeading?.heading}}</h2>
                            <div class="section-title-description">
                                <ng-container *ngIf="faqData">
                                    <p>{{faqData?.description}}</p>
                                </ng-container>
                            </div>
                        </div>
                        <!-- second faq page -->
                        <div class="section-accordion">
                            <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                <ngb-panel id="static-{{1+i}}" *ngFor="let item of faqContent | slice:0:4;let i = index">
                                    <ng-template ngbPanelTitle>
                                        {{item.question}}
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p>{{item.answer}}</p>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion> <!-- /.accordion -->
                        </div>

                        <!-- FIrst Faq page -->
                        <!-- <div class="iconic-list-wrapper iconic-list-wrapper-v2">
                            <div class="single-iconic-list iconic-list-v2 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div class="iconic-list-icon">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div class="iconic-list-body">
                                    <h5 class="iconic-list-title">Easy To Collaborate With User</h5>
                                </div>
                            </div>
                            <div class="single-iconic-list iconic-list-v2 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div class="iconic-list-icon">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div class="iconic-list-body">
                                    <h5 class="iconic-list-title">Manage Team & Users Flow</h5>
                                </div>
                            </div>
                            <div class="single-iconic-list iconic-list-v2 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div class="iconic-list-icon">
                                    <i class="fas fa-check"></i>
                                </div>
                                <div class="iconic-list-body">
                                    <h5 class="iconic-list-title">Upgrade Dashboard Systems</h5>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="section-right-image text-center animate-float-bob-y section-image-circle-shape wow fadeInDown" data-wow-delay="0ms" data-wow-duration="2000ms">
                <ng-container *ngIf="faqData">
                <img src="{{'https://backend.pinpointeye.com/FAQT/'+ faqData.image}}" alt="collaboration app screenshot" style="width:260px;height:527px" >
                </ng-container>
            </div> <!-- /.section-right-image -->
        </div> <!-- /.section-internal -->
    </div> <!-- /.container -->
</section>
