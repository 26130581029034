<!--   - it is present in home >about page -->
<br id="feature" #feature>
<br>
<br>
<br>
<section class="our-services-area">
    <div class="container">
        <div class="section-title text-center mb-55">
            <!-- <h2>Your Awesome <br class="d-none d-md-block"> Feature</h2> -->
            <h2>{{FeatureHeading?.heading}}</h2>
        </div>
        <div class="iconic-box-btn">
            <div class="row border-bottom-purple">
                <div class="col-lg-4 col-md-6" *ngFor="let item of details; let i = index">
                    <div class="single-iconic-box iconic-box-v5 wow fadeInUp single-feature-box single-feature-box-v3"
                        data-wow-delay="0.8s" data-wow-duration="1500ms">
                        <div class="iconic-box-body">
                            <div class="iconic-box-icon">
                                <!-- <i [class]="item.icon"></i> -->
                                <img class="feature"
                                    [src]="'https://backend.pinpointeye.com/Features/'+item.icon" alt=""
                                    width="60px" height="60px" />
                            </div>
                            <h5 class="iconic-box-title">{{item.heading}}</h5>
                            <!-- <p class="iconic-box-content">
                            {{item.description}} &nbsp;
                            <span style="color:black;text-decoration:underline;" (click)="openModal(readMore, details, i)">Read More</span>
                        </p> -->
                            <br>
                            <p class="iconic-box-content">{{ item.description.length >= maxLength ? (item.description |
                                slice:0:maxLength-3)
                                : item.description }} &nbsp; <span *ngIf="item?.heading == 'User Guide' && item.description.length < maxLength" style="color: #000;" > <a target="_blank" href="../../../../../assets/User Guide v11.pdf"> <b> Download </b> </a> </span>
                                <span *ngIf="item.description.length >= maxLength"
                                    (click)="openModal(readMore, details, i)">{{threeDots}}</span>
                                    
                            </p>
                            <br>
                            <!-- <div class="iconic-box-btn">
                            <div (click)="openModal(readMore, details, i)">Read More <i class="fas fa-arrow-right"></i></div>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #readMore>
    <!-- modal content goes here -->
    <div *ngIf="items">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"> {{items?.heading}}</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <div class="modal-body">
            {{items?.description}} <span *ngIf="items?.heading == 'User Guide'" style="color: #23B64D;" > <a target="_blank" href="../../../../../assets/User Guide v9.pdf"> <b> Download </b> </a> </span>
        </div>
        <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
    </div>

</ng-template>
