<br id="howtouse" #howtouse>
<br>
<br>
<br>
<!-- <br>
<br>
<br>
<br> -->

<section class="our-services  pb-80">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-100 text-left section-title-tuna">
                    <!-- <p class="section-title-description" style="color: #959595;">{{HTUImage?.text}}</p> -->
                    <h2>{{HTUImage?.bold_text}}</h2>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div *ngIf="HTUImage" class="section-right-image text-center animate-float-bob-y wow fadeInDown" data-wow-delay="0ms" data-wow-duration="2000ms">
                    <img src="{{'https://backend.pinpointeye.com/HtuImage/'+HTUImage?.image}}" alt="service left thumbnail" style="width:445px;height:900px;">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="icon-boxes-wrapper icon-boxes-grid">
                    <!-- Data -->
                    <div class="single-iconic-box iconic-box-v2" *ngFor="let item of details">
                        <!-- <div class="iconic-box-icon" [ngClass]="item.boxstyle"> -->
                            <div>
                            <!-- <i [src]="item.icon" [class]="item.icon"></i> -->
                            <img width="60px" height="60px" [src]="'https://backend.pinpointeye.com/HtuFeature/'+ item?.image" alt="">
                        </div>
                        <div class="iconic-box-body">
                            <h5 class="iconic-box-title">{{item?.heading}}</h5>
                            <p class="iconic-box-content">
                                {{item?.description}}
                            </p>
                        </div>
                    </div>
                    <!-- Data -->
                </div>
            </div>
        </div> <!-- /,row -->
    </div> <!-- /.container -->
</section>
