import { Component, OnInit } from '@angular/core';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
/** faq */
public faqContent: any;
public faqData:any;
public FaqHeading:any;
constructor(private faq: PinnPontService) { }

ngOnInit(): void {
  this.faq.getfaq().subscribe((res: any)=> {
    console.log('res-->', res.FAQ);
    this.faqContent =  res.FAQ;
    this.faqData = res.FAQT;
    this.FaqHeading = res.FaqHeading;
  }, (error) => {
      console.log('--->', error);  
  })
}

}
