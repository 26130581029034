import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { AboutComponent } from './about/about.component';
import { FeaturesComponent } from './howtouse/features.component';
import { TestimonialsComponent } from './feature/testimonials.component';
import { CounterComponent } from './flexipay/counter.component';
import { StatsComponent } from './faq/stats.component';
import { PageCtaComponent } from './download/page-cta.component';

const routes: Routes = [{
  path: '', component: HomeComponent
  // , children: [
    // { path: '', redirectTo: '', pathMatch: 'full' },
    // { path: 'about', component: AboutComponent,  data: { animationState: 'One' } },
    // { path: 'features', component: TestimonialsComponent, data: { animationState: 'Two' } },
    // { path: 'how-to-use', component: FeaturesComponent, data: { animationState: 'Three' } },
    // { path: 'faqs', component: StatsComponent, data: { animationState: 'Four' } },
    // { path: 'flexi-pay', component: CounterComponent, data: { animationState: 'Five' } },
    // { path: 'download', component: PageCtaComponent, data: { animationState: 'Six' } },
    // add additional child routes as needed
  // ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
