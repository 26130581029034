import { Component, Input, OnInit } from '@angular/core';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import data from "../../../data/counter.json";
import { Router } from '@angular/router';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {
  /** flexipay */
  public counter = data;
 public details:any;
 @Input() btnPass: any;
  constructor(private flexipay:PinnPontService, 
    private router: Router,) { }

  ngOnInit(): void {
    this.flexipay.getflexipay().subscribe((res: any)=> {
      this.details = res.Flexipy;
    }, (error) => {
        console.log('--->', error);    
    })
  }

  storeBtn() {
      // console.log(this.router.url);
      // if (this.router.url == '/') {
    this.btnPass = document.getElementById("playstore")?.scrollIntoView({behavior: 'smooth'});  
      // }else{
      //   this.router.navigateByUrl('/')
      // }
  }

}
