import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularDeviceInformationService, DeviceInfo } from 'angular-device-information';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import { GlobalConstants } from 'src/app/components/shared/global-contants';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  bsModalRef: any;
  deviceInfo: DeviceInfo | any;
  public details: any;
  public toastr: any;
  public errorMsg: any;
  responseMessage: string | any;

  constructor(
    private formBuilder: FormBuilder,
    private userService: PinnPontService,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient,
    private deviceInformationService: AngularDeviceInformationService
  ) {}

  loginForm: FormGroup | any = FormGroup;
  loginGuestForm: FormGroup | any = FormGroup;

  ngOnInit(): void {
    this.deviceInfo = this.deviceInformationService.getDeviceInfo();
    this.userService.getlogin().subscribe(
      (res: any) => {
        this.details = res.Login;
      },
      (error) => {
        console.log('--->', error);
      }
    );

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.loginGuestForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
    });
  }

  openModal(readMore: any) {
    this.bsModalRef = this.modalService.open(readMore, {
      windowClass: 'myCustomModalClass',
      scrollable: false,
      centered: true,
    });
    this.bsModalRef.result.then(
      () => {},
      () => {
        this.resetData();
      }
    );
  }

  resetData() {
    this.loginForm.reset();
    this.loginGuestForm.reset();
    this.errorMsg = null;
  }

  loginModalOpen(id: any) {
    this.bsModalRef = this.modalService.open(id, {
      windowClass: 'myCustomModalClass',
      scrollable: false,
      centered: true,
    });
    this.bsModalRef.result.then(
      () => {},
      () => {
        this.resetData();
      }
    );
  }

  videoModalOpen(id: any) {
    this.bsModalRef = this.modalService.open(id, {
      windowClass: 'myCustomModalClass',
      scrollable: false,
      centered: true,
    });
  }

  onSubmit() {
    // Clear error message before making a new request
    this.errorMsg = null;
    if (this.loginGuestForm.invalid) {
      this.loginGuestForm.markAllAsTouched();
      return;
    }
  
    const formData = new FormData();
    formData.append('name', this.loginGuestForm.value.name);
    formData.append('email', this.loginGuestForm.value.email);
  
    this.http.post<any>('https://admin.pinpointeye.com/api/user/guest-login', formData)
      .subscribe(
        (response) => {
          console.log('Guest login successful:', response);
          localStorage.setItem('token', response.user.id);
          localStorage.setItem('role_id', response.user.role_id);
          this.toastr = response?.message;
          if (this.bsModalRef) {
            this.bsModalRef.close();
          }
          this.router.navigate(['/downloads']);
        },
        (error) => {
          console.error('Guest login error:', error);
          this.errorMsg = error.error?.message || error.error?.errors || 'Login failed. Try again.';
        }
      );
  }

  onLoginSubmit() {
    this.errorMsg = null;
    this.toastr = null;
  
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
  
    const formData = new FormData();
    formData.append('LoginForm[device_type]', '1');
    formData.append('LoginForm[username]', this.loginForm.value.username);
    formData.append('LoginForm[password]', this.loginForm.value.password);
    formData.append('LoginForm[device_token]', 'test');
    formData.append('LoginForm[device_name]', 'test');
  
    this.http.post<any>('https://admin.pinpointeye.com/api/user/login', formData)
      .subscribe(
        (response) => {
          if (response && response['access-token'] && response.detail) {
            localStorage.setItem('token', response['access-token']);
            localStorage.setItem('user_id', response.detail.id.toString());
            localStorage.setItem('role_id', '0');
  
            this.toastr = response.message || 'Login successful.';
  
            if (this.bsModalRef) {
              this.bsModalRef.close();
            }
            this.router.navigate(['/downloads']);
          } else {
            this.errorMsg = response.message || 'Unexpected response from the server.';
          }
        },
        (error) => {
          if (error.error && error.error.message) {
            if (typeof error.error.message === 'string') {
              this.errorMsg = error.error.message;
            } else if (error.error.message.password) {
              this.errorMsg = error.error.message.password[0];
            } else {
              this.errorMsg = 'Login failed. Please check your credentials.';
            }
          } else {
            this.errorMsg = 'Login failed due to server error.';
          }
        }
      );
  }
  
}
