import { Component } from '@angular/core';
import { HelperService } from '../../helper/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends HelperService {
  redirectToExternalLink() {
    // set the URL of the external link
    window.open('https://mail.google.com/mail/u/0/#inbox?compose=new', '_blank');
    // window.location.href = 'https://mail.google.com/mail/u/0/#inbox?compose=new';
  }

}
