import { Component, OnInit } from '@angular/core';
import { PinnPontService } from 'src/app/components/helper/pinn-pont.service';
import data from "../../../data/faqs.json";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  public faqs = data;
  public pbClass:boolean = true;
  public details: any;
  public heading: any;
  public abtFeature:any;
  public lastAbtFeature: any;
  public firstElement :any;
  constructor(private about: PinnPontService) { }

  ngOnInit(): void {
    this.about.getabout().subscribe((res: any)=> {
      this.details = res;
      this.heading = res.AboutHeading;
      const restOfNumbers = res.AboutFeature.slice(0, res.AboutFeature.length - 1);
      this.abtFeature = restOfNumbers
      const feature = res.AboutFeature.length - 1;
      this.lastAbtFeature = res.AboutFeature[feature]
      // this.firstElement = res.AboutFeature.shift()      
    }, (error) => {
        console.log('--->', error);    
    })
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

}
